/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from "react-router-dom";
import supabase from "utils/supabase";
import call from 'api/call';
import { APIS } from 'api/constants';
import snackbar from "stores/snackbarStore";
import stores from 'stores';
import dataStore from "stores/dataStore";
import customersStore from 'stores/Customer';
import assumptionStore from 'stores/assumptionStore';
import portfoliosStore from 'stores/portfoliosStore';



const AuthProvider = observer(({ children }) => {
  const { organisationSettings } = stores;

    const history = useHistory()

    useEffect(() => {
      supabase.auth.getSession().then(async ({ data: { session } }) => {
        if (!session || !localStorage.getItem("TOKEN")) {
          localStorage.clear();
          history.push("/login")
        }
      })

      const { data: { subscription } } = supabase.auth.onAuthStateChange(async (_event, session) => {

        if(!_event || !session) {
          return
        }
        if (_event === 'INITIAL_SESSION') {
          console.log("AUTHPROVIDER INITIAL_SESSION")
          // const response = await call(APIS.LOGIN, { Token: session.access_token });
          //       if(!response.success) {
          //           history.push("/login")
          //       }

          //       localStorage.setItem("TOKEN", response.message.Token);
          //       localStorage.setItem("USER", JSON.stringify(session.user));
          //       localStorage.setItem("ORGANISATIONID", response.message.MasterUserID);
        
        
                await organisationSettings.initializeOrganisationSettings(session.user.id);
        
                await Promise.all([
                    dataStore.fetchPreviousBankDate(),
                    dataStore.fetchCurrencyCodes(),
                    customersStore.getAll(),
                    assumptionStore.getAssumptions(),
                    portfoliosStore.fetchAllPortfolios(),
                    
                ]);
        } else if (_event === "SIGNED_IN") {
          console.log("SIGNED_IN")
          // handle sign in event
        } else if (_event === 'SIGNED_OUT') {
          console.log("SIGNED_OUT")
          // handle sign out event
        } else if (_event === 'PASSWORD_RECOVERY') {
          console.log("PASSWORD_RECOVERY")
          // handle password recovery event
        } else if (_event === 'TOKEN_REFRESHED') {
          console.log("TOKEN_REFRESH")
          const response = await call(APIS.LOGIN, { Token: session.access_token });
          if(!response.success) {
              snackbar.handleOpen(response.meta.data.ErrorText, "error")
              return;
          }
  
          localStorage.setItem("TOKEN", response.message.Token);
          localStorage.setItem("ORGANISATIONID", response.message.MasterUserID);
          localStorage.setItem("TOKEN", JSON.stringify(session.access_token));
          // // handle token refreshed event

          // extract jwt and store it in memory for further use in application.
          // Avoid frequent calls to supabase.auth.getSession() for the same purpose.
          // There is a background process that keeps track of when the session should be refreshed so you will always receive valid tokens by listening to this event.
          // The frequency of this event is related to the JWT expiry limit configured on your project.


        } else if (_event === 'USER_UPDATED') {
          // handle user updated event
        }

      })

      return () => subscription.unsubscribe()
    }, [history])

    return (
      <>{children}</>
    )
})

export default AuthProvider;
