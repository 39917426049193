import { action, makeObservable, observable, toJS, runInAction } from "mobx";
import { blankPage } from "./constants";
import { initialPortfolioWidgets } from "../portfolioWidgets/constants";
import pdfEditorSettingsStore from "../PDFEditorSettings/PDFEditorSettingsStore";
import portfolioWidgetsStore from "../portfolioWidgets/portfolioWidgetsStore";
import portfoliosStore from "../portfoliosStore";
import { pdfWidgetBaseData } from "stores/portfolioWidgets/constants";
import { internalapiCall } from "api/call";
import { IRISK_BO_API_URL } from "api/constants";
import snackbarStore from "stores/snackbarStore";

class PDFEditorStore {
    report = null
    list = []
    hasInitializedReport = false;
    filteredReportPages = [];

    constructor() {
        makeObservable(this, {
            list: observable,
            report: observable,
            filteredReportPages: observable,
            hasInitializedReport: observable,
            addPage: action,
            initialize: action,
            updateLayout: action,
            addWidgetToLayout: action,
            setReportPeriod: action,
            updatePageHeadline: action,
            saveDisclaimerText: action,
            getListOfReports: action,
            applySettingsToPages: action,
        })
    }

    applySettingsToPages(newSettings = null) {
        let settings = this.report.settings;
        if(newSettings) {
            settings = newSettings;
        }
        let filteredPages = toJS(this.report.pagesList);
        const copyStatePagesList = toJS(this.report.pagesList)

        // First filter holding pages
        if(!settings.includeHolding) {
            filteredPages = copyStatePagesList.filter((pageId) => !pageId.includes("holding"))
        }
        this.filteredReportPages = filteredPages;

    }

    async getListOfReports() {
        // Get organisationID from organisationSettingsStore
        const organisationId = JSON.parse(localStorage.getItem("ORGANISATIONID"))
        // get UserId from userStore
        const userId = JSON.parse(localStorage.getItem("USER")).id

        try {
            const response = await internalapiCall("POST", `${IRISK_BO_API_URL}/report/pdf/list`, { userId, organisationId })
            if(response.status === 200) {
                runInAction(() => {
                    this.list = response.data.data
                })
            }
        } catch (error) {
            snackbarStore.handleOpen("Error fetching list of reports", "error")
        }
    }

    async saveDisclaimerText(disclaimerText, cb) {
        if(this.report && this.report._id) {
            const response = await internalapiCall("POST", `${IRISK_BO_API_URL}/report/pdf/update-disclaimer`, { reportId: this.report._id, disclaimerText })
            if(response.data.status === "Ok") {
                snackbarStore.handleOpen("Disclaimer updated", "success")
                if(cb) {
                    cb()
                }
            } else {
                snackbarStore.handleOpen("Error updating disclaimer", "error")
            }
        }
    }

    async initialize(params) {
        this.hasInitializedReport = true;

        try {
            // Get organisationID from organisationSettingsStore
            const organisationId = JSON.parse(localStorage.getItem("ORGANISATIONID"))
            // get UserId from userStore
            const userId = JSON.parse(localStorage.getItem("USER")).id
    
            const URL = `${IRISK_BO_API_URL}/report/pdf/${organisationId}/${userId}`
            console.table(["START PDF INIT", URL, params])
    
            const pdfReport = await internalapiCall("GET", URL)
    
            if(!pdfReport || !pdfReport.data || !pdfReport.data.data) {
                console.log("NO REPORT FOUND")
                snackbarStore.handleOpen("No report found", "error")
                return;
            }
    
            runInAction(() => {
                this.report = pdfReport.data.data
                pdfEditorSettingsStore.initialize(this.report.settings);
            })
    
            if(params.customerId && params.portfolioId && portfoliosStore.fetchedId !== params.portfolioId) {
                console.log("FETCHING PORTFOLIO DETAILS")
                await portfoliosStore.fetchCustomerPortfolioDetails(params.customerId, params.portfolioId, this.report.settings.startDate, this.report.settings.endDate);
            }
            // First we fetch calculations
            if(params.customerId && params.portfolioId && portfolioWidgetsStore.fetchedId !== params.portfolioId) {
                console.log("FETCHING DYNAMIC WIDGETS")
                await portfolioWidgetsStore.initializeDynamicWidgets(params.customerId, params.portfolioId)
                portfolioWidgetsStore.setFetchedId(params.portfolioId)
            }
    
            runInAction(() => {
                const copyState = toJS(this.report)
                copyState.pagesList.forEach((pageId) => {
                    const page = copyState.pagesContent[pageId]
                    page.widgets = page.widgets.map((widget) => {
                        return {...widget, ...pdfWidgetBaseData }
                    })
                })
    
                if(this.report.pagesList.length === 0) {
                    this.addPage();
                }
    
                this.hasInitializedReport = false;
            });

        } catch (error) {
            snackbarStore.handleOpen("Error initializing PDF Editor", "error")
        }
        
    }

    updatePageHeadline(pageId, newHeadline) {
        this.report.pagesContent[pageId].headline = newHeadline;
    }

    async updateLayout(pageId, newLayout) {
        console.log("UPDATE LAYOUT", this.report)
        runInAction(() => {
            this.report.pagesContent[pageId].widgets = newLayout
        })

        if(this.report && this.report._id) {
            await internalapiCall("POST", `${IRISK_BO_API_URL}/report/pdf/update-layout`, { reportId: this.report._id, pageId: pageId, widgets: newLayout })
        }

        this.applySettingsToPages();
    }

    // setSelectedPageId(pageId) {
    //     this.selectedPageId = pageId;
    // }

    addWidgetToLayout(widget, pageId) {
        const widgetJS = toJS(widget)
        const widgetItem = { i: widgetJS.id, x: 0, y: Infinity, static: false, ...widgetJS.sizes.paper };

        this.report.pagesContent[pageId].widgets.push(widgetItem)
    }

    addPage(prevPageIndex = null, page = null) {
        if(this.report === null) {
            return 
        }

        if(page === null) {
            page = blankPage;
        }
        if(prevPageIndex !== null) {
            // add page to the right index in the pagesList'
            this.report.pagesList.splice(prevPageIndex + 1, 0, page.id);
        } else {
            this.report.pagesList.push(page.id);
        }
        const pageToAdd = page === null ? blankPage : page;
        this.report.pagesContent[page.id] = { ...pageToAdd, id: page.id };
        this.applySettingsToPages();
        return page.id;
    }

    createHoldingPages(pageIndex, rowsForCurrentPage, holdingData){
        if(!this.report) {
            return;
        }
        
        const programmedHoldingID = `holding_${pageIndex}`;
        // Create a new page
        const newHoldingPage = {
            id: programmedHoldingID,
            name: 'Holding',
            headline: 'Holding',
            displayHeaderBanner: true,
            displayFooter: true,
            widgets: []
        }
        const currentPageId = this.addPage(null, newHoldingPage);
        const currentPage = this.report.pagesContent[currentPageId];
    
        // Add widget to that page
        currentPage.widgets.push({
            i: programmedHoldingID,
            x: 0,
            y: 0,
            w: 3,
            h: Infinity,
            static: false
        });
    
        // Create a new system generated widget
        portfolioWidgetsStore.injectWidgetData(programmedHoldingID, {
            ...holdingData,
            rows: rowsForCurrentPage,
        });
    
        portfolioWidgetsStore.widgets[programmedHoldingID] = {
            ...initialPortfolioWidgets.holding,
            id: programmedHoldingID,
        };
    }

    setReportPeriod(period) {
        this.report.settings.period = period;
    }
}

const store = (window.pdfEditorStore = new PDFEditorStore());

export default store