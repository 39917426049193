import { action, makeObservable, observable, runInAction, toJS } from "mobx";
// import { defaultDashboardReport } from "./constants";
// import {mobXStoreHelper} from "utils/mobXStoreHelper"
import { IRISK_BO_API_URL } from "api/constants";
import organisationSettingsStore from "stores/organisationSettingsStore";
import { insightsWidgetBaseData } from "stores/portfolioWidgets/constants";
import portfolioWidgetsStore from "stores/portfolioWidgets";
import portfoliosStore from "stores/portfoliosStore";
import {internalapiCall} from "api/call";

// import { LARGE_PAPER, WIDGET_TYPES, widgetCategories } from "stores/portfolioWidgets/constants";

class DashboardEditorStore {
    report = null;
    selectedPageId = null;
    hasInitializedReport = false;

    constructor() {
        makeObservable(this, {
            report: observable,
            selectedPageId: observable,
            hasInitializedReport: observable,
            initialize: action,
            updateLayout: action,
            addWidgetToLayout: action,
            updateWidgetSize: action,
            setReportPeriod: action

        })
    }

    async initialize(params) {
        this.hasInitializedReport = true;
        console.log("START INITIALIZE", params)
        
        // Get organisationID from organisationSettingsStore
        const organisationId = JSON.parse(localStorage.getItem("ORGANISATIONID"))
        // get UserId from userStore
        const userId = JSON.parse(localStorage.getItem("USER")).id
        // Fetch report from API

        
        const insights = await internalapiCall("GET", `${IRISK_BO_API_URL}/report/insights/${organisationId}/${userId}`)
        runInAction(() => {
            this.report = insights.data.data
        })

        if(params.customerId && params.portfolioId && portfoliosStore.fetchedId !== params.portfolioId) {
            console.log("FETCHING PORTFOLIO DETAILS")
            await portfoliosStore.fetchCustomerPortfolioDetails(params.customerId, params.portfolioId, this.report.settings.startDate, this.report.settings.endDate);
        }

        // First we fetch calculations
        if(params.customerId && params.portfolioId && portfolioWidgetsStore.fetchedId !== params.portfolioId) {
            console.log("FETCHING DYNAMIC WIDGETS")
            await portfolioWidgetsStore.initializeDynamicWidgets(params.customerId, params.portfolioId)
            portfolioWidgetsStore.setFetchedId(params.portfolioId)
        }
        
        runInAction(() => {
            const copyState = toJS(this.report.widgets)
    
            this.report.widgets = copyState.map((widget) => {
                return {...widget, ...insightsWidgetBaseData }
            })
    
            // Remove duplicates based on the 'i' value, keeping the last occurrence
            const uniqueWidgetsMap = new Map();
    
            for (let i = this.report.widgets.length - 1; i >= 0; i--) {
                const widget = this.report.widgets[i];
                uniqueWidgetsMap.set(widget.i, widget);
            }

            this.report.widgets = Array.from(uniqueWidgetsMap.values()).reverse();
        })

        
        portfolioWidgetsStore.widgets =  portfolioWidgetsStore.widgets = Object.fromEntries(
            Object.entries(portfolioWidgetsStore.widgets).filter(([key]) => !key.includes('holding'))
        );
        console.log("END INITIALIZE")
        this.hasInitializedReport = false;
    }

    async updateLayout(newLayout) {
        
            console.log("UPDATE LAYOUT", this.report)
            this.report.widgets = newLayout
            if(this.report && this.report._id) {
                await internalapiCall("POST", `${IRISK_BO_API_URL}/report/insights/update-layout`, { reportId: this.report._id, widgets: newLayout })
            }
        
    }

    async addWidgetToLayout(newWidget) {
        const stateCopy = toJS(this.report)
        const isDuplicate = this.report.widgets.some(existingWidget => existingWidget.id === newWidget.id);
        
        if(isDuplicate) {
            return
        }
        const widgetItem = { i: newWidget.id, x: 0, y: Infinity, ...newWidget.sizes.lg, ...insightsWidgetBaseData };
        stateCopy.widgets.push(widgetItem);
        this.report = stateCopy;
    }

    async setReportPeriod(newPeriod) {
        const stateCopy = toJS(this.report)
        stateCopy.settings.period = newPeriod
        this.report = stateCopy
        
        await internalapiCall("POST", `${IRISK_BO_API_URL}/report/insights/update-period`, { reportId: this.report._id, period: newPeriod })
    }

    updateWidgetSize(newItem) {
        const reportCopyState = toJS(this.report)
        reportCopyState.widgets.map((widget) => {
            if (widget.i === newItem.i) {
                return { ...widget, w: newItem.w, h: newItem.h };
              }
              return widget;
        })

        this.report = reportCopyState
    }
}

const store = (window.dashboardEditor = new DashboardEditorStore());

export default store